import React, { FC, useEffect } from 'react';
import { HttpError, HttpStatusCode } from '@core/http';

import * as Styled from './ErrorPage.styles';
import { Link } from 'react-router-dom';
import { Button } from '@styles/shared';
import { Typography } from '@styles/shared/Typography';
import notFoundImg from '@assets/images/not-found.svg';
import { useTranslation } from 'react-i18next';
import { useLayoutContext } from '@layout/Layout';

interface ErrorPageProps {
  error?: HttpError;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = HttpError.notFound }) => {
  const { t } = useTranslation();

  const [, setProps] = useLayoutContext();

  useEffect(() => {
    setProps(value => ({
      ...value,
      backLink: { label: t('global.back'), previous: true },
      background: `#ffffff`,
      headerDark: false,
    }));
  }, [setProps, t]);

  return (
    <Styled.ErrorPageContainer>
      <Styled.ErrorPageText>
        <Typography $level="h3" as="h1">
          {HttpStatusCode.NOT_FOUND === error.status ? t('errors.not_found.title') : t('errors.any_error.title')}
        </Typography>
        <Typography $level="bodyStandard">
          {HttpStatusCode.NOT_FOUND === error.status ? t('errors.not_found.content') : t('errors.any_error.content')}
        </Typography>
      </Styled.ErrorPageText>
      {HttpStatusCode.NOT_FOUND === error.status && (
        <Styled.ErrorPageImageContainer>
          <img src={notFoundImg} alt="illu" />
        </Styled.ErrorPageImageContainer>
      )}
      <Styled.ErrorPageButtonContainer>
        <Button as={Link} to="/">
          {t('errors.button')}
        </Button>
      </Styled.ErrorPageButtonContainer>
    </Styled.ErrorPageContainer>
  );
};

export default ErrorPage;
