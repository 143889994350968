import styled from 'styled-components';

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  h1 {
    margin: 0;
  }
`;

export const ErrorPageText = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 32px;
`;

export const ErrorPageImageContainer = styled.div`
  img {
    max-width: 100%;
    max-height: 400px;
  }
`;

export const ErrorPageButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 20px;
`;
