import React, { FC, useEffect, useRef } from 'react';
import { useBooleanState } from '@shared/hooks/boolean';
import * as Styled from './HeaderUser.styles';
import { Profile } from '@modules/auth/model';
import { Typography } from '@styles/shared/Typography';
import { Link } from 'react-router-dom';
import { useAuthContext } from '@modules/auth/context';
import { useTranslation } from 'react-i18next';

interface HeaderUserProps {
  profile: Profile;
  headerDark: boolean;
}

const HeaderUser: FC<HeaderUserProps> = ({ profile, headerDark }) => {
  const popupRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, open, close] = useBooleanState();

  const { logout } = useAuthContext();

  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (popupRef.current && e.target instanceof Element && !popupRef.current.contains(e.target)) {
        close();
      }
    };

    document.addEventListener('mousedown', handleClickOutside, { passive: true });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [close]);

  return (
    <Styled.HeaderUserContainer>
      <Styled.HeaderUserButton $headerDark={headerDark} onClick={open} />
      {isOpen && (
        <Styled.HeaderUserPopup ref={popupRef}>
          <Styled.HeaderUserPopupCloseButton onClick={close} />
          <Styled.HeaderUserPopupTop>
            <Typography $level="bodyStandardBold">{profile.name}</Typography>
          </Styled.HeaderUserPopupTop>
          <Styled.HeaderUserPopupContent>
            <Typography $level="navPrimary" as={Link} to="/cgv" onClick={close}>
              {t('header.cgv')}
            </Typography>
            <Typography $level="navPrimary" as={Link} to="/legal-notice" onClick={close}>
              {t('header.legal_notice')}
            </Typography>
            <Typography $level="navPrimary" as={Link} to="/privacy-policy" onClick={close}>
              {t('header.privacy_policy')}
            </Typography>
          </Styled.HeaderUserPopupContent>
          <Styled.HeaderUserPopupBottom as="div" onClick={logout}>
            {t('header.logout')}
          </Styled.HeaderUserPopupBottom>
        </Styled.HeaderUserPopup>
      )}
    </Styled.HeaderUserContainer>
  );
};

export default HeaderUser;
