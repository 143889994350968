import { useFetchTask } from '@core/http/hooks';
import TextPage from '@shared/components/text-page/TextPage';
import { renderHttpRemoteData } from '@shared/utils/render';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as LegalService from '../service';

const LegalNotice: FC = () => {
  const { t } = useTranslation();
  const [legalNotice] = useFetchTask(LegalService.getLegalNotice);

  return renderHttpRemoteData(legalNotice, legalNotice => (
    <TextPage text={legalNotice} pageTitle={t('legal-notice.page_title')} />
  ));
};

export default LegalNotice;
