import styled from 'styled-components';
import { rgba, tertiaryColor } from '@styles/utils';
import { Button } from '@styles/shared';
import userWhiteIcon from '@assets/icons/user/user-white.svg';
import userIcon from '@assets/icons/user/user.svg';
import closeIcon from '@assets/icons/modal/close.svg';

export const HeaderUserContainer = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 10px;
`;

export const HeaderUserButton = styled(Button)<{ $headerDark: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 !important;
  border-radius: 8px;
  background: url(${props => (props.$headerDark ? userWhiteIcon : userIcon)}) no-repeat center center
    ${props => (props.$headerDark ? rgba(tertiaryColor(100), 0.25) : '')};
  height: 32px;
  width: 32px;

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const HeaderUserPopup = styled.div`
  position: absolute;
  display: grid;
  grid-row-gap: 10px;
  top: -1px;
  right: -2px;
  padding: 24px 0 19px 0;
  background: ${tertiaryColor(100)};
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  width: calc(100vw - 64px);
  z-index: 1000;
`;

export const HeaderUserPopupCloseButton = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 43px;
  height: 43px;
  border: none;
  background: url(${closeIcon}) no-repeat center center;
  outline: none;
  cursor: pointer;
`;

export const HeaderUserPopupTop = styled.div`
  background: url(${userIcon}) no-repeat left 24px center;
  background-size: 32px;
  padding-left: 70px;
`;

export const HeaderUserPopupContent = styled.div`
  display: grid;
  row-gap: 10px;
  margin-top: 5px;
  padding: 0 24px;
`;

export const HeaderUserPopupBottom = styled.button`
  color: ${tertiaryColor(400)};
  font-size: 13px;
  font-weight: 500;
  border-top: 1px solid #f2f2f2;
  margin-top: 10px;
  padding: 15px 24px 0 24px;
  cursor: pointer;
`;
