import Page, { PageProps } from '@layout/page/Page';
import React, { FC } from 'react';

interface TextPageProps {
  text: string;
  pageTitle: string;
}

const TextPage: FC<TextPageProps> = ({ text, pageTitle }) => {
  const pageProps: PageProps = {
    title: pageTitle,
    padding: '20px',
    layoutProps: {
      backLink: { label: pageTitle, previous: true },
      background: `#ffffff`,
      headerDark: false,
    },
  };

  return (
    <Page {...pageProps}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Page>
  );
};

export default TextPage;
