import styled from 'styled-components';

export const PageContainer = styled.div<{ $backgroundColor?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageContentWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
`;

export const PageContent = styled.div<{ $padding?: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${props => (props.$padding ? props.$padding : '20px')};
  margin: 0 auto;
`;

export const PageChildren = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;
