import { useFetchTask } from '@core/http/hooks';
import TextPage from '@shared/components/text-page/TextPage';
import { renderHttpRemoteData } from '@shared/utils/render';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as LegalService from '../service';

const Cgv: FC = () => {
  const { t } = useTranslation();
  const [cgv] = useFetchTask(LegalService.getCgv);

  return renderHttpRemoteData(cgv, cgv => <TextPage text={cgv} pageTitle={t('cgv.page_title')} />);
};

export default Cgv;
