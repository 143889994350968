import { useFetchTask } from '@core/http/hooks';
import TextPage from '@shared/components/text-page/TextPage';
import { renderHttpRemoteData } from '@shared/utils/render';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as LegalService from '../service';

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();
  const [privayPolicy] = useFetchTask(LegalService.getPrivacyPolicy);

  return renderHttpRemoteData(privayPolicy, privayPolicy => (
    <TextPage text={privayPolicy} pageTitle={t('privacy-policy.page_title')} />
  ));
};

export default PrivacyPolicy;
