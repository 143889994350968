import { z } from 'zod';
import { Newtype } from 'newtype-ts';

export type ResetPasswordToken = Newtype<{ readonly TOKEN: unique symbol }, string> & string;
export type ActivationToken = Newtype<{ readonly TOKEN: unique symbol }, string> & string;

export interface Profile {
  name: string;
  language: string;
}

export const authenticateSchema = z.object({
  email: z.string().nonempty(),
  password: z.string().nonempty(),
});

export type AuthenticateParams = z.infer<typeof authenticateSchema>;

export const forgotPasswordSchema = z.object({
  email: z.string().nonempty(),
});

export type ForgotPasswordParams = z.infer<typeof forgotPasswordSchema>;

export interface ResetPasswordParams {
  password: string;
}

export const passwordsSchema = z
  .object({
    password: z.string().nonempty(),
    confirmPassword: z.string().nonempty('Champs requis'),
  })
  .refine(value => value?.password && value.confirmPassword && value.password === value.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Les mots de passe ne correspondent pas',
  });

export type PasswordsFormBody = z.infer<typeof passwordsSchema>;

export interface ActivationDetail {
  email: string;
}

export interface ActivationParams {
  password: string;
}

export const STORAGE_TOKEN_KEY = 'tokens';

export interface Token {
  token: string;
  refreshToken: string | null;
}
