import { Profile } from '@modules/auth/model';
import LockScreen from '@shared/components/lock-screen/LockScreen';
import { constVoid } from 'fp-ts/function';
import React, { createContext, Dispatch, FC, SetStateAction, Suspense, useContext, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header/Header';
import * as Styled from './Layout.styles';
import { DebouncedLineLoader } from './loaders/line-loader/LineLoader';

interface LayoutProps {
  profile: Profile;
}

export interface LayoutContextProps extends Omit<LayoutProps, 'profile'> {
  backLink?: {
    label: string;
    to?: string;
    previous?: boolean;
  };
  headerDark?: boolean;
  background?: string;
}

type LayoutContextValue = [props: LayoutContextProps, setProps: Dispatch<SetStateAction<LayoutContextProps>>];

const LayoutContext = createContext<LayoutContextValue>([{ headerDark: false }, constVoid]);

const Layout: FC<LayoutProps> = ({ profile }) => {
  const [props, setProps] = useState<LayoutContextProps>(() => ({}));

  const ctx = useMemo<LayoutContextValue>(() => [props, setProps], [props]);

  return (
    <>
      <LockScreen />
      <LayoutContext.Provider value={ctx}>
        <Header profile={profile} />
        <Styled.LayoutContainer $background={props.background}>
          <Styled.LayoutContent>
            <Suspense fallback={<DebouncedLineLoader />}>
              <Outlet />
            </Suspense>
          </Styled.LayoutContent>
        </Styled.LayoutContainer>
      </LayoutContext.Provider>
    </>
  );
};

export default Layout;

export function useLayoutContext() {
  return useContext(LayoutContext);
}
