import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import ReactModal from 'react-modal';

import { configureSentry } from '@shared/modules/sentry/utils';

import { registerSW } from 'virtual:pwa-register';

import '@core/i18n';

import '@styles/css';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';

ReactModal.setAppElement('#root');

configureSentry();

registerSW();

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <Suspense fallback={<DebouncedLineLoader />}>
      <App />
    </Suspense>,
  );
}
