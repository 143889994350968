import styled, { css } from 'styled-components';

import { mediaMax } from '@styles/utils';
import background from '@assets/images/login/background.svg';

export const AccessLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${background}) no-repeat center;
  background-size: cover;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export const AccessLayoutContent = styled.div<{ $hasFooter: boolean }>`
  display: flex;
  flex: 1 1 auto;

  ${props =>
    props.$hasFooter
      ? css`
          align-items: center;
          justify-content: center;
        `
      : css`
          align-items: flex-start;
          justify-content: center;

          img {
            margin-bottom: 100px;
          }
        `};
`;

export const AccessLayoutCard = styled.div`
  text-align: center;
  padding: 50px 32px;
  width: 100%;

  @media screen and (${mediaMax.mobile}) {
    padding: 20px 25px 40px 25px;
  }
`;

export const AccessLayoutLogoContainer = styled.div`
  text-align: center;
  padding-bottom: 20px;

  > img {
    width: 218px;
    max-width: 100%;
  }
`;
