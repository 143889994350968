import React, { FC } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';

import { safeLazy } from '@core/router';
import { Profile } from '@modules/auth/model';
import Layout from '@layout/Layout';
import AppRoutes from '@modules/app/routes';
import { renderOptional } from '@shared/utils/render';
import { useAuthContext } from '@modules/auth/context';
import { Routes } from '@core/router/Routes';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';

const LoginPage = safeLazy(() => import('@modules/auth/LoginPage'));

interface PrivateRoutesProps {
  profile: Profile;
}

const PrivateRoutes: FC<PrivateRoutesProps> = ({ profile }) => (
  <Routes>
    <Route element={<Layout profile={profile} />}>
      <Route path="/*" element={<AppRoutes />} />
    </Route>
  </Routes>
);

const RootRoutes: FC = () => {
  const { profile } = useAuthContext();
  let location = useLocation();

  return (
    <SentryProvider profile={profile}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/*"
          element={
            <>
              {renderOptional(
                profile,
                profile => (
                  <PrivateRoutes profile={profile} />
                ),
                () => (
                  <Navigate to="/login" state={{ referrer: location }} />
                ),
              )}
            </>
          }
        />
      </Routes>
    </SentryProvider>
  );
};

export default RootRoutes;
