import React, { FC, PropsWithChildren, useEffect } from 'react';
import { LayoutContextProps, useLayoutContext } from '@layout/Layout';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import Seo from '@shared/modules/seo/Seo';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as Styled from './Page.styles';

export const PAGE_SCROLLER_ID = 'page-scroller';
export interface PageProps {
  title?: string;
  loading?: boolean;
  padding?: string;
  layoutProps?: LayoutContextProps;
}

const Page: FC<PropsWithChildren<PageProps>> = ({ title, loading, padding, layoutProps, children }) => {
  const [, setProps] = useLayoutContext();

  useEffect(() => {
    setProps(value => ({ ...value, ...layoutProps }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProps]);

  const isLoading = loading || children == null;

  const seoTitle: string | undefined = pipe(
    O.fromNullable(title),
    O.getOrElse(() => 'QRPhone'),
  );

  return (
    <Styled.PageContainer>
      <Seo title={seoTitle} />

      {isLoading && <DebouncedLineLoader />}

      <Styled.PageContentWrapper id={PAGE_SCROLLER_ID}>
        <Styled.PageContent $padding={padding}>
          <Styled.PageChildren>{children}</Styled.PageChildren>
        </Styled.PageContent>
      </Styled.PageContentWrapper>
    </Styled.PageContainer>
  );
};

export default Page;
