import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    font-family: 'Maven Pro', sans-serif;
    height: 100%;
    overflow: hidden;
  }

  #root {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  a {
     text-decoration: none;
  }

  p {
    margin: 5px 0;
  }
`;
