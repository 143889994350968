import i18n from 'i18next';
import Backend from 'i18next-http-backend';

import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

const debug = process.env.NODE_ENV === 'development';

export const languages = ['fr', 'en', 'es'] as const;

export type Language = typeof languages[number];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: languages,
    fallbackLng: 'en',
    preload: ['en'],
    debug,
    detection: {
      order: ['localStorage', 'navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/static/i18n/{{lng}}.json',
    },
  });

export default i18n;
