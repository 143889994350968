import React, { FC, ReactNode, PropsWithChildren } from 'react';

import logo from '@assets/logos/logo.svg';

import * as Styled from './AccessLayout.styles';

interface AccessLayoutProps {
  footer?: ReactNode;
}

const AccessLayout: FC<PropsWithChildren<AccessLayoutProps>> = ({ footer = true, children }) => {
  return (
    <Styled.AccessLayoutContainer>
      <Styled.AccessLayoutContent $hasFooter={!!footer}>
        <Styled.AccessLayoutCard>
          <Styled.AccessLayoutLogoContainer>
            <img src={logo} alt="Logo Promété" />
          </Styled.AccessLayoutLogoContainer>
          {children}
        </Styled.AccessLayoutCard>
      </Styled.AccessLayoutContent>
      {footer}
    </Styled.AccessLayoutContainer>
  );
};

export default AccessLayout;
