import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpError, HttpStatusCode, HttpTask } from './model';

import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { identity, constVoid, Lazy } from 'fp-ts/function';
import { refreshToken } from '../../modules/auth/service';
import { defaultAxiosInstance } from './config';

const WHITE_LIST_REDIRECT = ['/authenticate', '/profile'];

const isWhiteList = (url: string) => WHITE_LIST_REDIRECT.filter(whiteUrl => url.includes(whiteUrl)).length > 0;

function checkNetworkAvailability<E>(): HttpTask<void, E> {
  return pipe(
    navigator.onLine,
    TE.fromPredicate(identity, () => HttpError.offline as HttpError<E>),
    TE.map(constVoid),
  );
}

function transformRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E> {
  return pipe(
    checkNetworkAvailability<E>(),
    TE.chain(() => TE.tryCatch(request, err => HttpError.fromAxiosError<E>(err as any))),
    TE.map(res => res.data),
  );
}

function httpErrorHandler<R, E>(
  originalRequest: Lazy<Promise<AxiosResponse<R>>>,
): (err: HttpError<E>) => HttpTask<R, E> {
  // if (
  //   error.status >= 400 &&
  //   ![
  //     HttpStatusCode.UNAUTHORIZED,
  //     HttpStatusCode.FORBIDDEN,
  //     HttpStatusCode.NOT_FOUND,
  //     HttpStatusCode.CONFLICT,
  //   ].includes(error.status)
  // ) {
  //   console.error(error);
  //   logSentryHttpError(`[http] error ${error.status} on ${O.getOrElse(() => 'unknown')(error.url)} path`, error);
  // }

  return err => {
    const urlRefreshAllow = pipe(
      err.url,
      O.exists(u => !u.includes('/authenticate')),
    );

    if (HttpStatusCode.UNAUTHORIZED === err.status && urlRefreshAllow) {
      return pipe(
        refreshToken(),
        TE.chain(() => transformRequest(originalRequest)),
        TE.mapLeft(() => {
          const redirectToLogin = pipe(
            err.url,
            O.exists(url => err.status === HttpStatusCode.UNAUTHORIZED && !isWhiteList(url)),
          );

          if (redirectToLogin) {
            window.location.replace('/login');
          }

          return err;
        }),
      );
    } else if (err.status >= 500) {
      return T.delay(500)(transformRequest(originalRequest));
    } else {
      return TE.left(err);
    }
  };
}

function handleRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E> {
  return pipe(transformRequest<R, E>(request), TE.orElse(httpErrorHandler(request)));
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> {
  return handleRequest(() => defaultAxiosInstance.get(url, config));
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E> {
  return handleRequest(() => defaultAxiosInstance.post(url, data, config));
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> {
  return handleRequest(() => defaultAxiosInstance.delete(url, config));
}

export const httpService = {
  get,
  post,
  delete: del,
};
