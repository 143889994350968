import { useLayoutContext } from '@layout/Layout';
import { Profile } from '@modules/auth/model';
import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Styled from './Header.styles';
import HeaderUser from './user/HeaderUser';
import logo from '@assets/logos/logo-paysage.svg';
import logoDark from '@assets/logos/logo-dark-paysage.svg';

interface HeaderProps {
  profile: Profile;
}

const Header: FC<HeaderProps> = ({ profile }) => {
  const [{ headerDark, backLink }] = useLayoutContext();

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Styled.HeaderContainer>
      {backLink ? (
        <Styled.HeaderBackLink
          $level="navPrimary"
          as={backLink.previous ? undefined : Link}
          to={backLink.previous ? '' : backLink.to ?? '../'}
          onClick={backLink.previous ? handleGoBack : undefined}
        >
          {backLink.label}
        </Styled.HeaderBackLink>
      ) : (
        <Link to="/">
          <img src={headerDark ? logo : logoDark} alt="logo" width={138} />
        </Link>
      )}
      <HeaderUser profile={profile} headerDark={headerDark ?? false} />
    </Styled.HeaderContainer>
  );
};

export default Header;
