import { BackgroundColor, Color, DefaultTheme } from 'styled-components';

const primaryColors: Color = {
  100: '#defff6',
  200: '#a7f6e0',
  300: '#70edca',
  400: '#39e3b4',
  500: '#07dea3',
  600: '#02b885',
  700: '#01956c',
  800: '#01956c',
  900: '#00503a',
};

const secondaryColors: Color = {
  100: '#d8e8fe',
  200: '#8abbfd',
  300: '#62a4fc',
  400: '#3b8efc',
  500: '#1477fb',
  600: '#0161d6',
  700: '#125bc3',
  800: '#104fa3',
  900: '#0e3b76',
};

const tertiaryColors: Color = {
  100: '#ffffff',
  200: '#efefef',
  300: '#dddddd',
  400: '#ababb1',
  500: '#4c5258',
  600: '#474a58',
  700: '#2c2e3d',
  800: '#242636',
  900: '#1b1b24',
};

const backgroundColors: BackgroundColor = {
  100: '#f7f7f7',
  200: '#f2f3f4',
  300: '#faf8f8',
  400: '#fdf9f4',
};

const theme: DefaultTheme = {
  colors: {
    primary: primaryColors,
    secondary: secondaryColors,
    tertiary: tertiaryColors,
    background: backgroundColors,
    error: '#f95d5d',
  },
  font: {
    sizes: {
      h1: '31px',
      h2: '22px',
      h3: '20px',
      h4: '16px',
      h5: '14px',
      navPrimaryRed: '13px',
      navPrimary: '16px',
      navSecondary: '16px',
      bodyLarge: '20px',
      bodySemiLarge: '16px',
      bodyStandard: '16px',
      bodyStandardBold: '16px',
      bodyMedium: '14px',
      bodyMediumBold: '14px',
      bodySmall: '14px',
      bodyExtraSmall: '10px',
      date: '15px',
      input: '16px',
      fav: '20px',
    },
  },
  breakpoints: {
    large: 1280,
    desktop: 1080,
    tablet: 950,
    mobile: 600,
  },
};

export default theme;
