import styled, { css, DefaultTheme, FlattenInterpolation, FontSizeKey, ThemeProps } from 'styled-components';
import { errorColor, fontSize, secondaryColor, tertiaryColor } from '@styles/utils';

export interface TypographyProps {
  $level: FontSizeKey;
  $color?: string;
  $weight?: string;
  $italic?: boolean;
  $uppercase?: boolean;
  $inverted?: boolean;
  $ellipsis?: boolean;
  $maxWidth?: number;
}

export const typographyStyles: Record<FontSizeKey, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  h1: css`
    font-size: ${fontSize.h1};
    font-weight: bold;
  `,
  h2: css`
    color: ${secondaryColor(800)};
    font-size: ${fontSize.h2};
    font-weight: 600;
  `,
  h3: css`
    color: ${secondaryColor(800)};
    font-size: ${fontSize.h3};
    font-weight: 600;
    line-height: normal; ;
  `,
  h4: css`
    font-size: ${fontSize.h4};
    font-weight: 600;
  `,
  h5: css`
    color: ${tertiaryColor(900)};
    font-size: ${fontSize.h5};
    font-weight: 600;
  `,
  navPrimaryRed: css`
    color: ${errorColor};
    font-size: ${fontSize.navPrimaryRed};
    font-weight: 500;
  `,
  navPrimary: css`
    color: #434343;
    font-size: ${fontSize.navPrimary};
    font-weight: 500;
  `,
  navSecondary: css`
    color: #000000;
    font-size: ${fontSize.navSecondary};
    font-weight: 600;
  `,
  bodyLarge: css`
    color: ${tertiaryColor(900)};
    font-size: ${fontSize.bodyLarge};
    font-weight: bold;
  `,
  bodySemiLarge: css`
    color: #133270;
    font-size: ${fontSize.bodySemiLarge};
    font-weight: 500;
  `,
  bodyStandard: css`
    color: ${tertiaryColor(900)};
    font-size: ${fontSize.bodyStandard};
    font-weight: normal;
    line-height: 1.38;
  `,
  bodyStandardBold: css`
    color: ${tertiaryColor(900)};
    font-size: ${fontSize.bodyStandardBold};
    font-weight: bold;
  `,
  bodyMedium: css`
    color: ${tertiaryColor(900)};
    font-size: ${fontSize.bodyMedium};
    font-weight: normal;
  `,
  bodyMediumBold: css`
    color: ${tertiaryColor(900)};
    font-size: ${fontSize.bodyMediumBold};
    font-weight: 600;
  `,
  bodySmall: css`
    color: ${tertiaryColor(900)};
    font-size: ${fontSize.bodySmall};
    font-weight: normal;
  `,
  bodyExtraSmall: css`
    color: ${tertiaryColor(900)};
    font-size: ${fontSize.bodyExtraSmall};
    font-weight: 500;
  `,
  date: css`
    color: ${secondaryColor(500)};
    font-size: ${fontSize.date};
    font-weight: 600;
  `,
  input: css`
    color: ${tertiaryColor(900)};
    font-size: ${fontSize.input};
    font-weight: 500;
  `,
  fav: css`
    color: ${tertiaryColor(700)};
    font-size: ${fontSize.fav};
    font-weight: bold;
  `,
};

export const Typography = styled.p<TypographyProps>`
  ${props => typographyStyles[props.$level]};
  ${props => fontSize[props.$level]};

  ${props =>
    props.$color &&
    css`
      color ${props.$color};
    `};

  ${props =>
    props.$weight &&
    css`
      font-weight: ${props.$weight};
    `};

  ${props =>
    props.$inverted &&
    css`
      color: ${tertiaryColor(100)};
    `};

  ${props =>
    props.$italic &&
    css`
      font-style: italic;
    `};

  ${props =>
    props.$uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${props =>
    props.$ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `};

  ${props =>
    props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth}px;
    `};
`;
