import { Typography } from '@styles/shared/Typography';
import styled from 'styled-components';
import arrowIcon from '@assets/icons/arrow.svg';
import { mediaMax } from '@styles/utils';

export const HeaderContainer = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 17px 32px;
  height: 80px;

  z-index: 5;

  @media screen and (${mediaMax.tablet}) {
    display: flex;
  }
`;

export const HeaderBackLink = styled(Typography)`
  position: relative;
  padding-left: 25px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background: url(${arrowIcon}) no-repeat center;
    transform: rotate(180deg);
  }
`;
