import { backgroundColor, mediaMax } from '@styles/utils';
import styled from 'styled-components';

export const LayoutContainer = styled.div<{ $background?: string }>`
  position: relative;
  display: none;
  padding-top: 80px;
  background: ${props => (props.$background ? props.$background : backgroundColor(100))};
  height: 100%;

  @media screen and (${mediaMax.tablet}) {
    display: flex;
  }
`;

export const LayoutContent = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
`;
