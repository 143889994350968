import React, { FC, PropsWithChildren, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import RootRoutes from 'routes';
import { ThemeProvider } from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { AuthContextProvider } from '@modules/auth/context';
import { GlobalStyles } from '@styles/global';
import theme from '@styles/theme';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { HttpError } from '@core/http';
import { useTranslation } from 'react-i18next';
import ToastsProvider from '@shared/components/toasts/ToastsProvider';

const ScrollRestoration: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

const App: FC = () => {
  const { i18n } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <GlobalStyles />
        <ToastsProvider />

        <Helmet>
          <html lang={i18n.language} />
        </Helmet>

        <BrowserRouter>
          <ScrollRestoration>
            <AuthContextProvider>
              <Routes>
                <Route path="/*" element={<RootRoutes />} />
                <Route path="*" element={<ErrorPage error={HttpError.notFound} />} />
              </Routes>
            </AuthContextProvider>
          </ScrollRestoration>
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
