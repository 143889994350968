import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { rgba as polishedRgba } from 'polished';
import { Color, ColorKey, DefaultTheme, ThemeProps } from 'styled-components';

function getColor(color: Partial<Color>): (key: Partial<ColorKey>) => string {
  return key => color[key] ?? '';
}

export function primaryColor(key?: ColorKey): (props: ThemeProps<DefaultTheme>) => string {
  return props =>
    pipe(
      O.fromNullable(key),
      O.getOrElse<ColorKey>(() => 500),
      getColor(props.theme.colors.primary),
    );
}

export function secondaryColor(key?: ColorKey): (props: ThemeProps<DefaultTheme>) => string {
  return props =>
    pipe(
      O.fromNullable(key),
      O.getOrElse<ColorKey>(() => 500),
      getColor(props.theme.colors.secondary),
    );
}

export function tertiaryColor(key?: ColorKey): (props: ThemeProps<DefaultTheme>) => string {
  return props =>
    pipe(
      O.fromNullable(key),
      O.getOrElse<ColorKey>(() => 100),
      getColor(props.theme.colors.tertiary),
    );
}

export function backgroundColor(key?: ColorKey): (props: ThemeProps<DefaultTheme>) => string {
  return props =>
    pipe(
      O.fromNullable(key),
      O.getOrElse<ColorKey>(() => 100),
      getColor(props.theme.colors.background),
    );
}

export function errorColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.error;
}

export function rgba(
  colorFn: (props: ThemeProps<DefaultTheme>) => string,
  amount: number,
): (props: ThemeProps<DefaultTheme>) => string {
  return props => polishedRgba(colorFn(props), amount);
}
