import { primaryColor, tertiaryColor } from '@styles/utils';
import styled, { css, DefaultTheme, FlattenInterpolation, keyframes, ThemeProps } from 'styled-components';
import addIcon from '@assets/icons/button/add.svg';

export type ButtonType = 'primary' | 'secondary' | 'tertiary';

const phenoDateStyle: Record<ButtonType, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  primary: css`
    color: ${tertiaryColor(100)};
    background-color: ${primaryColor()};
    border-radius: 28px;
    font-size: 16px;
    font-weight: bold;
    padding: 18px 20px;
    width: 100%;
  `,
  secondary: css`
    color: ${primaryColor()};
    background-color: ${tertiaryColor(100)};
    border: 2px solid ${primaryColor()};
    border-radius: 28px;
    font-size: 16px;
    font-weight: bold;
    padding: 18px 20px;
    width: 100%;
  `,
  tertiary: css`
    color: ${primaryColor()};
    background: url(${addIcon}) no-repeat center left 18px ${primaryColor(100)};
    border: 2px solid ${primaryColor()};
    border-radius: 25px;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 18px 8px 35px;
  `,
};

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(250deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Button = styled.button<{ $type?: ButtonType; $loading?: boolean }>`
  position: relative;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  ${props => phenoDateStyle[props.$type ?? 'primary']};

  ${props =>
    props.$loading &&
    css`
      opacity: 1;
      pointer-events: none;
      overflow: hidden;
      color: transparent;

      &:before {
        border-radius: 50%;
        animation: ${spinnerAnimation} 1.2s linear infinite;
        content: '';
        position: absolute;
        height: 16px;
        width: 16px;
        top: calc(50% - (21px / 2));
        left: calc(50% - (19px / 2));
        border: 2px solid ${tertiaryColor(100)};
        border-top-color: transparent;
        background: none;
        z-index: 1;
      }

      &:after {
        display: none;
      }
    `};
`;
