import localforage from 'localforage';

const storage = localforage.createInstance({
  name: 'promete-pwa',
  storeName: 'storage',
  description: 'Promete Pwa Storage',
});

function getItem<T>(key: string): Promise<T | null> {
  return storage.getItem(key);
}

function setItem<T>(key: string, value: T): Promise<T> {
  return storage.setItem(key, value);
}

function removeItem(key: string): Promise<void> {
  return storage.removeItem(key);
}

const storageService = {
  getItem,
  setItem,
  removeItem,
};

export default storageService;
