import axios, { AxiosRequestConfig } from 'axios';
import { v4 as uuid } from 'uuid';

import { TRANSACTION_ID_HEADER_KEY } from '.';

import * as T from 'fp-ts/Task';
import * as TO from 'fp-ts/TaskOption';
import { pipe } from 'fp-ts/function';
import { getAuthToken } from '@modules/auth/service';

import queryString from 'query-string';
import { API_BASE_URL } from '@core/constants';

function getCommonHeaders(): Record<string, string> {
  return {
    [TRANSACTION_ID_HEADER_KEY]: uuid(),
  };
}

function getBearerHeader(): T.Task<Record<string, string>> {
  return pipe(
    getAuthToken(),
    TO.fold(
      () => T.of({}),
      ({ token }) =>
        T.of({
          Bearer: token,
        }),
    ),
  );
}

function paramsSerializer(params: any): string {
  return queryString.stringify(params);
}

export const defaultAxiosInstance = axios.create({
  paramsSerializer,
  withCredentials: true,
  baseURL: API_BASE_URL,
  timeout: 30000,
});

defaultAxiosInstance.interceptors.request.use(config => {
  return pipe(
    getBearerHeader(),
    T.map<Record<string, string>, AxiosRequestConfig>(bearer => ({
      ...config,
      headers: {
        ...getCommonHeaders(),
        ...bearer,
        ...config.headers,
      },
    })),
  )();
});
