import { Typography } from '@styles/shared/Typography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AccessLayout from '../access-layout/AccessLayout';
import * as Styled from './LockScreen.styles';

const LockScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.LockScreenContainer>
      <AccessLayout footer={false}>
        <Typography $level="h2" as="h2" $inverted>
          {t('lock_screen.title')}
        </Typography>
        <Typography $level="bodyStandard" $inverted>
          {t('lock_screen.content')}
        </Typography>
      </AccessLayout>
    </Styled.LockScreenContainer>
  );
};

export default LockScreen;
