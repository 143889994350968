import { mediaMin } from '@styles/utils';
import styled from 'styled-components';

export const LockScreenContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  @media screen and (${mediaMin.tablet}) {
    display: flex;
  }
`;
