import { HttpError, httpService, HttpStatusCode, HttpTask } from '@core/http';
import { AuthenticateParams, Profile, STORAGE_TOKEN_KEY, Token } from '@modules/auth/model';
import { constVoid, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as TO from 'fp-ts/TaskOption';
import storageService from '@core/storage/service';
import * as O from 'fp-ts/Option';

export function saveAuthToken(token: Token): T.Task<Token> {
  return () => storageService.setItem(STORAGE_TOKEN_KEY, token);
}

export function getAuthToken(): TO.TaskOption<Token> {
  return pipe(() => storageService.getItem<Token>(STORAGE_TOKEN_KEY), T.map(O.fromNullable));
}

export function removeAuthToken(): T.Task<void> {
  return () => storageService.removeItem(STORAGE_TOKEN_KEY);
}

export function authenticate(params: AuthenticateParams): HttpTask<void> {
  return pipe(
    httpService.post<Token>('/authenticate', params),
    TE.chain(res => TE.rightTask(saveAuthToken(res))),
    TE.map(constVoid),
  );
}

export function refreshToken(): HttpTask<void> {
  return pipe(
    getAuthToken(),
    TO.chainOptionK(token => O.fromNullable(token.refreshToken)),
    TO.fold(
      () => TE.left(HttpError.fromStatusCode(HttpStatusCode.UNAUTHORIZED)),
      token => httpService.post<Token>('/authenticate/refresh', { token }),
    ),
    TE.chain(res => TE.rightTask(saveAuthToken(res))),
    TE.map(constVoid),
  );
}

export function getProfile(token?: string): HttpTask<Profile> {
  const requestHeaders = pipe(
    O.fromNullable(token),
    O.map(token => ({ Bearer: token })),
    O.toUndefined,
  );

  return httpService.get('/profile', { headers: requestHeaders });
}

export function logout(): HttpTask {
  return httpService.delete('/authenticate');
}
