import { errorColor, mediaMax, secondaryColor } from '@styles/utils';
import { createGlobalStyle } from 'styled-components';

export const ToastsStyles = createGlobalStyle`
  .Toastify__toast-container {
    bottom: 0;
    z-index: 100000 !important;

    .Toastify__toast {
        position: absolute;
        bottom: 0;
      }
    
    .app-toast {
      min-height: 50px;
      padding: 10px;
      border-radius: 10px;

      .Toastify__toast-icon svg {
        fill: #ffffff;
      }
      
      &.Toastify__toast--success {
        color: #ffffff;
        background: ${secondaryColor(800)};
        font-size: 14px;
        text-align: center;
        width: calc(100% - 40px);
      }
      
      &.Toastify__toast--error {
        color: #ffffff;
        background: ${errorColor};
        text-align: center;
        width: calc(100% - 40px);
      }

      @media screen and (${mediaMax.large}) {
        margin: 20px;
      }
    }
  }
`;
