import { httpService, HttpTask } from '@core/http';

const URI = '/legal';

export function getCgv(): HttpTask<string> {
  return httpService.get(`${URI}/cgv`);
}

export function getLegalNotice(): HttpTask<string> {
  return httpService.get(`${URI}/legalNotice`);
}

export function getPrivacyPolicy(): HttpTask<string> {
  return httpService.get(`${URI}/privacyPolicy`);
}
