import React, { FC } from 'react';

import { safeLazy } from '@core/router';
import { Route } from 'react-router-dom';
import { Routes } from '@core/router/Routes';
import Cgv from './legals/cgv/Cgv';
import LegalNotice from './legals/legal-notice/LegalNotice';
import PrivacyPolicy from './legals/privacy-policy/PrivacyPolicy';

const PlotsRoutes = safeLazy(() => import('@modules/app/plots/routes'));

const AppRoutes: FC = () => (
  <Routes>
    <Route path="/*" element={<PlotsRoutes />} />
    <Route path="/cgv" element={<Cgv />} />
    <Route path="/legal-notice" element={<LegalNotice />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
  </Routes>
);

export default AppRoutes;
